import * as React from "react"

import Layout from "src/components/layout"
import Seo from "src/components/seo"
import Slider from "react-slick";

import * as styles from "src/scss/_recruit.module.scss"
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import ProcessWeb from "src/images/recruit-process-web.jpg"
import ProcessMobile from "src/images/recruit-process-mobile.jpg"

import ninno from "src/images/recruit/ninno1.jpg"
import lab1 from "src/images/recruit/lab-1.jpg"
import lab3 from "src/images/recruit/lab-3.jpg"
import lab4 from "src/images/recruit/lab-4.jpg"
import lab6 from "src/images/recruit/lab-6.jpg"
import lab7 from "src/images/recruit/lab-7.jpg"
import lab8 from "src/images/recruit/lab-8.jpg"
import hfItems from "src/images/recruit/hf-items.jpg"
import labFront1 from "src/images/recruit/lab-front1.jpg"
import tokamachi1 from "src/images/recruit/tokamachi1.jpg"
import tokamachi2 from "src/images/recruit/tokamachi2.jpg"
import tokamachi3 from "src/images/recruit/tokamachi3.jpg"
import tokamachi4 from "src/images/recruit/tokamachi4.jpg"
import nagaoka1 from "src/images/recruit/nagaoka1.jpg"
import nagaoka2 from "src/images/recruit/nagaoka2.jpg"
import nagaoka3 from "src/images/recruit/nagaoka3.jpg"
import recruitpop from "src/images/recruit/recruitpop.jpg"

export default function RecruitPage () {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 4000,
    arrows: false
  };
  return (
    <Layout>
      <Seo title="採用情報" />
      <div className="pageWrapper">
        <div className="pageTtl">
          <h1>採用情報</h1>
        </div>

        {/* スライダーの写真の大きさは900px x 600px または３：２で作成する*/}

        <Slider {...settings} className={styles.recruitSlider}>
          <div>
            <h3><img src={recruitpop } alt="求人チラシ" /></h3>
          </div>
          <div>
            <h3><img src={ hfItems } alt="ハードオフグッズ" /></h3>
          </div>
          <div>
            <h3><img src={ labFront1 } alt="ハードオフ未来ラボ入り口" /></h3>
          </div>
          <div>
            <h3><img src={ tokamachi1 } alt="十日町開発センター入り口" /></h3>
          </div>
          <div>
            <h3><img src={ nagaoka3 } alt="長岡CSセンター入り口" /></h3>
          </div>
          <div>
            <h3><img src={ ninno } alt="未来ラボの様子" /></h3>
          </div>
          <div>
            <h3><img src={ tokamachi3 } alt="十日町開発センターの様子" /></h3>
          </div>
          <div>
            <h3><img src={ lab1 } alt="未来ラボの様子" /></h3>
          </div>
          <div>
            <h3><img src={ nagaoka2 } alt="長岡CSセンターの様子" /></h3>
          </div>
          <div>
            <h3><img src={ lab3 } alt="未来ラボの様子" /></h3>
          </div>
          <div>
            <h3><img src={ tokamachi4 } alt="十日町開発センターの様子" /></h3>
          </div>
          <div>
            <h3><img src={ lab4 } alt="未来ラボの様子" /></h3>
          </div>
          <div>
            <h3><img src={ nagaoka1 } alt="長岡CSセンターの様子" /></h3>
          </div>
          <div>
            <h3><img src={ lab6 } alt="未来ラボの様子" /></h3>
          </div>
          <div>
            <h3><img src={ tokamachi2 } alt="十日町開発センターの様子" /></h3>
          </div>
          <div>
            <h3><img src={ lab7 } alt="未来ラボの様子" /></h3>
          </div>
          <div>
            <h3><img src={ lab8 } alt="未来ラボの様子" /></h3>
          </div>
        </Slider>

        <h2 className="h2Blue">募集要項・エントリー</h2>
        <p className="productMsg">
          {/* 主にハードオフグループのシステムを中心に新規開発・改修をしています。<br />
          現在以下の職種を募集しております。 */}
          リンクチャネル株式会社では採用を通年行っております。新しいユーザー体験をあなたの手で創ってみませんか？
        </p>

        <div className={styles.careerNewgradWrapper}>
          <a href="/recruit/mid-career/" className={styles.careerNewgradContent}>
            <div className={styles.careerNewgradTtl}>
              <p>中途採用</p>
            </div>
            <div className={styles.careerNewgradLink}>
              <span>詳しく見る</span>
              <svg xmlns="http://www.w3.org/2000/svg" height="30px" viewBox="0 0 30 30" width="30px">
                <rect fill="none" height="30" width="30"/>
                <path d="M15,5l-1.41,1.41L18.17,11H2V13h16.17l-4.59,4.59L15,19l7-7L15,5z"/>
              </svg>
            </div>
          </a>

          <a href="/recruit/new-graduate/" className={styles.careerNewgradContent}>
            <div className={styles.careerNewgradTtl}>
              <p>新卒採用</p>
            </div>
            <div className={styles.careerNewgradLink}>
              <span>詳しく見る</span>
              <svg xmlns="http://www.w3.org/2000/svg" height="30px" viewBox="0 0 30 30" width="30px">
                <rect fill="none" height="30" width="30"/>
                <path d="M15,5l-1.41,1.41L18.17,11H2V13h16.17l-4.59,4.59L15,19l7-7L15,5z"/>
              </svg>
            </div>
          </a>
        </div>

        <h2 className="h2Blue">採用の流れ</h2>
        <p className="productMsg">
          選考プロセスの基本的な流れについてご説明します。
        </p>
        <img src={ ProcessWeb } alt="採用の流れ" className={styles.processWeb}/>
        <img src={ ProcessMobile } alt="採用の流れ" className={styles.processMobile}/>
        <p className="italic">※上記は変更となる可能性もございます。</p>
      </div>
    </Layout>
  );
}
